.component-93- {
  height: 62px;
  margin-top: 18px;
  position: relative;
}
.component-93-1 {
  align-items: center;
  display: flex;
  min-width: 279px;
}
.overlap-group {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 29px;
  display: flex;
  height: 56px;
  min-width: 204px;
  padding: 1.5px 55px;
}
.x24 {
  height: 62px;
  margin-left: 13px;
  object-fit: cover;
  width: 62px;
}
.xyz2423 {
  letter-spacing: 0.00px;
  line-height: 21px;
  min-height: 36px;
  min-width: 87px;
  white-space: nowrap;
}
