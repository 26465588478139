.asset-1 {
  background-position: 50% 50%;
  background-size: cover;
  bottom: 29px;
  height: 67px;
  position: absolute;
  right: calc(47.83% - 36px);
  width: 76px;
}
.component-43-1 {
  height: 160px;
  left: 0px;
  position: absolute;
  top: 17px;
  width: 135px;
}
.component-45-1 {
  background-color: var(--white);
  border-radius: 5px;
  top: 74%;
  height: 55px;
  left: 20.00%;
  position: absolute;
  width: 239px;
}
.component-52-1 {
  border-radius: 4px;
  height: 25px;
  position: absolute;
  right: 45px;
  top: 33px;
  width: 25px;
}
.component-71-1 {
  height: 220px;
  position: absolute;
  right: calc(47.47% - 145px);
  top: calc(16.72% - 37px);
  width: 306px;
}
.component-72-1 {
  height: 312px;
  left: calc(50.00% - 297px);
  position: absolute;
  top: 0px;
  width: 594px;
}
.component-73-1 {
  bottom: calc(35.70% - 154px);
  height: 431px;
  left: -118px;
  margin-bottom: 15px;
  position: absolute;
  width: calc(100.00% + 189px);
  top: 23%;
}
.croods5 {
  height: 265px;
  object-fit: cover;
  position: absolute;
  right: calc(103.53% - 351px);
  top: calc(130.80% - 346px);
  width: 339px;
}
.en-home {
  background-color: #F8EADB;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.get-invite-code {
  bottom: calc(5.56% - 2px);
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(46.94% - 89px);
  white-space: nowrap;
  width: 190px;
}
.logov3 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.text-1 {
  -webkit-text-stroke: 1px var(--licorice);
  height: calc(100.00% - 2px);
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  right: calc(37.50% - 6px);
  top: 6px;
  white-space: nowrap;
  width: 17px;
}
.text-2 {
  height: 70px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: relative;
  left: 10%;
  top: 84%;
  white-space: nowrap;
  width: 300px;
}
.title {
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 50px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
.x1 {
  height: 220px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: calc(50.00% - 110px);
  width: 220px;
}
