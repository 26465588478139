.component-45-3 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: calc(3.70% - 2px);
  height: 55px;
  left: calc(50.00% - 120px);
  position: absolute;
  width: 239px;
}
.component-74-1 {
  height: 18.53%;
  position: absolute;
  right: calc(49.66% - 129px);
  top: 44.03%;
  width: 259px;
}
.en-typeform-home {
  background-color: #F8EADB;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  width: 100%;
}
.line-1 {
  bottom: calc(101.72% - 5px);
  height: 5px;
  left: calc(50.00% - 130px);
  object-fit: cover;
  position: absolute;
  width: 259px;
}
.logov7 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.prove-yourself {
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(25.40% - 45px);
  top: calc(105.56% - 39px);
  white-space: nowrap;
  width: 176px;
}
.text-7 {
  height: 140px;
  left: calc(0.54% - 1px);
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: calc(133.33% - 187px);
  white-space: nowrap;
  width: 167px;
}
.x3 {
  height: 235px;
  object-fit: cover;
  position: absolute;
  right: calc(48.24% - 113px);
  top: calc(18.37% - 43px);
  width: 235px;
}
