.ch-typeform-home {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 405px;
  position: relative;
  width: 100%;
}
.component-62-1 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 30px;
  height: 55px;
  left: calc(50.00% - 120px);
  position: absolute;
  width: 239px;
}
.component-92-1 {
  bottom: calc(48.67% - 56px);
  height: 116px;
  left: 56px;
  position: absolute;
  width: 259px;
}
.line-12 {
  height: 5px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: -2px;
  width: 100%;
}
.logov8 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.ta {
  -webkit-text-stroke: 1px var(--licorice);
  color: var(--licorice);
  font-family: 'Poppins', Helvetica;
  font-size: 40px;
  height: calc(100.00% - 12px);
  left: 1px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  width: calc(100.00% - 15px);
}
.text-8 {
  -webkit-text-stroke: 1px var(--licorice);
  bottom: 0px;
  height: 38px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  right: calc(32.18% - 49px);
  white-space: nowrap;
  width: 152px;
}
.x32 {
  height: 235px;
  left: calc(41.18% - 97px);
  object-fit: cover;
  position: absolute;
  top: 95px;
  width: 235px;
}
