.asset-12 {
  bottom: 0px;
  height: 157px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  width: 196px;
}
.component-70-1 {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 293px;
}
.x23 {
  height: 293px;
  left: calc(50.00% - 146px);
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 293px;
}
