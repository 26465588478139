.component-22-2 {
  height: 234px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 259px;
}
.component-45-5 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 29px;
  height: 55px;
  position: absolute;
  right: calc(44.91% - 107px);
  width: 239px;
}
.component-65-1 {
  height: 54px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 177px;
}
.component-66-1 {
  bottom: 0px;
  height: 28px;
  position: absolute;
  right: 0px;
  width: 284px;
}
.component-67-1 {
  bottom: 0px;
  height: 95px;
  left: calc(50.00% - 143px);
  position: absolute;
  width: 286px;
}
.component-69-1 {
  height: 340px;
  position: absolute;
  right: calc(41.67% - 119px);
  top: calc(26.67% - 91px);
  width: 286px;
}
.en-typeform-pass {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 406px;
  position: relative;
  width: 100%;
}
.logov11 {
  height: 122px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.next {
  bottom: calc(11.11% - 4px);
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(49.18% - 28px);
  white-space: nowrap;
  width: 56px;
}
.perfect {
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
.text-11 {
  color: var(--tundora);
  font-family: 'Poppins', Helvetica;
  font-size: 20px;
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
