.component-47-5 {
  background-color: var(--white);
  border-radius: 29px;
  height: calc(100.00% - 6px);
  left: 0px;
  position: absolute;
  top: 3px;
  width: 204px;
}
.xyz24232 {
  bottom: calc(7.50% - 3px);
  height: 36px;
  left: calc(47.01% - 41px);
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  white-space: nowrap;
  width: 87px;
}
