.android {
  height: 43px;
  margin-left: 29px;
  object-fit: cover;
  width: 124px;
}
.app-store {
  height: 43px;
  object-fit: cover;
  width: 125px;
}
.component-94-1 {
  align-items: flex-start;
  display: flex;
  margin-left: 2.0px;
  margin-top: 40px;
  min-width: 278px;
}
.download-the-app {
  letter-spacing: 0.00px;
  line-height: 15px;
  margin-right: 132.49px;
  margin-top: 5px;
  min-height: 23px;
  min-width: 141px;
  white-space: nowrap;
}
.email-my-codes2 {
  background-color: transparent;
  border: 0;
  height: 25px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 0px;
  width: 274px;
}
.email-my-codes2::placeholder {
  color: rgba(112,112,112,0.6);
}
.en-cods {
  align-items: center;
  background-color: var(--albescent-white);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
}
.enjoy {
  align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 45px;
  margin-top: 5px;
  min-height: 56px;
  min-width: 131px;
  white-space: nowrap;
}
.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 222px;
  width: 185px;
}
.flex-row {
  align-items: flex-end;
  align-self: flex-start;
  display: flex;
  height: 212px;
  margin-bottom: -42px;
  margin-top: -12px;
  min-width: 459px;
}
.flex-row-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 5px;
  min-width: 278px;
}
.group-48 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 1.0px;
  margin-top: 4px;
  min-height: 292px;
  position: relative;
  width: 279px;
}
.line-2 {
  height: 2px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 271px;
}
.logov {
  height: 122px;
  object-fit: cover;
  width: 122px;
}
.overlap-group4 {
  height: 82px;
  position: relative;
  width: 278px;
}
.x25 {
  display: block;
  height: 61px;
  left: 217px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 61px;
opacity: 0;transform: scale(1.1);
}
.x7 {
  height: 165px;
  object-fit: cover;
  position: absolute;
  right: -53px;
  top: calc(6.80% - 11px);
  width: 165px;
}
