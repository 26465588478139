.asset-13 {
  background-position: 50% 50%;
  background-size: cover;
  bottom: 124px;
  height: 67px;
  left: calc(43.23% - 33px);
  position: absolute;
  width: 76px;
}
.ch-home {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height:100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.component-43-12 {
  height: 120px;
  left: 0px;
  position: absolute;
  top: 36px;
  width: 150px;
}
.component-45-8 {
  background-color: var(--white);
  border-radius: 5px;
  top: 74%;
  height: 55px;
  left: 20%;
  position: absolute;
  width: 239px;
}
.component-52-2 {
  border-radius: 4px;
  height: 25px;
  position: absolute;
  right: 45px;
  top: 33px;
  width: 25px;
}
.component-71-3 {
  height: 220px;
  position: absolute;
  right: calc(47.00% - 143px);
  top: calc(16.72% - 37px);
  width: 305px;
}
.component-72-12 {
  height: 312px;
  left: calc(50.00% - 297px);
  position: absolute;
  top: 0px;
  width: 594px;
}
.component-73-3 {
  bottom: calc(38.90% - 160px);
  height: 411px;
  left: -118px;
  position: absolute;
  width: calc(100.00% + 189px);
}
.croods52 {
  height: 265px;
  object-fit: cover;
  position: absolute;
  right: calc(103.53% - 351px);
  top: calc(130.80% - 346px);
  width: 339px;
}
.en {
  -webkit-text-stroke: 1px var(--licorice);
  height: calc(100.00% - 5px);
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  right: calc(25.00% - 5px);
  top: 4px;
  white-space: nowrap;
  width: 21px;
}
.logov4 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.text-3 {
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 50px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
.text-4 {
  height: 50px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  left: 18%;
  top:  85%;
  white-space: nowrap;
  width: 270px;
}
.text-5 {
  height: 38px;
  left: calc(45.54% - 58px);
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  top: calc(102.94% - 39px);
  white-space: nowrap;
  width: 127px;
}
.x12 {
  height: 220px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: calc(50.00% - 110px);
  width: 220px;
}
