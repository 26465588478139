@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,300,700);
.component-93- {
  height: 62px;
  margin-top: 18px;
  position: relative;
}
.component-93-1 {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  min-width: 279px;
}
.overlap-group {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-color: var(--white);
  border-radius: 29px;
  display: -webkit-flex;
  display: flex;
  height: 56px;
  min-width: 204px;
  padding: 1.5px 55px;
}
.x24 {
  height: 62px;
  margin-left: 13px;
  object-fit: cover;
  width: 62px;
}
.xyz2423 {
  letter-spacing: 0.00px;
  line-height: 21px;
  min-height: 36px;
  min-width: 87px;
  white-space: nowrap;
}

.android {
  height: 43px;
  margin-left: 29px;
  object-fit: cover;
  width: 124px;
}
.app-store {
  height: 43px;
  object-fit: cover;
  width: 125px;
}
.component-94-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  margin-left: 2.0px;
  margin-top: 40px;
  min-width: 278px;
}
.download-the-app {
  letter-spacing: 0.00px;
  line-height: 15px;
  margin-right: 132.49px;
  margin-top: 5px;
  min-height: 23px;
  min-width: 141px;
  white-space: nowrap;
}
.email-my-codes2 {
  background-color: transparent;
  border: 0;
  height: 25px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 0px;
  width: 274px;
}
.email-my-codes2::-webkit-input-placeholder {
  color: rgba(112,112,112,0.6);
}
.email-my-codes2:-ms-input-placeholder {
  color: rgba(112,112,112,0.6);
}
.email-my-codes2::placeholder {
  color: rgba(112,112,112,0.6);
}
.en-cods {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--albescent-white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
}
.enjoy {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 45px;
  margin-top: 5px;
  min-height: 56px;
  min-width: 131px;
  white-space: nowrap;
}
.flex-col {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 222px;
  width: 185px;
}
.flex-row {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 212px;
  margin-bottom: -42px;
  margin-top: -12px;
  min-width: 459px;
}
.flex-row-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  margin-top: 5px;
  min-width: 278px;
}
.group-48 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 1.0px;
  margin-top: 4px;
  min-height: 292px;
  position: relative;
  width: 279px;
}
.line-2 {
  height: 2px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 271px;
}
.logov {
  height: 122px;
  object-fit: cover;
  width: 122px;
}
.overlap-group4 {
  height: 82px;
  position: relative;
  width: 278px;
}
.x25 {
  display: block;
  height: 61px;
  left: 217px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 61px;
opacity: 0;-webkit-transform: scale(1.1);transform: scale(1.1);
}
.x7 {
  height: 165px;
  object-fit: cover;
  position: absolute;
  right: -53px;
  top: calc(6.80% - 11px);
  width: 165px;
}

.component-47-5 {
  background-color: var(--white);
  border-radius: 29px;
  height: calc(100.00% - 6px);
  left: 0px;
  position: absolute;
  top: 3px;
  width: 204px;
}
.xyz24232 {
  bottom: calc(7.50% - 3px);
  height: 36px;
  left: calc(47.01% - 41px);
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  white-space: nowrap;
  width: 87px;
}

.component-48-5 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 1px);
}
.component-48-6 {
  top: calc(21.80% - 14px);
}
.component-48-8 {
  bottom: calc(34.60% - 21px);
  top: unset;
}
.x243 {
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 62px;
}

.android-1 {
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 1px);
}
.android2 {
  bottom: 43px;
  height: 43px;
  position: absolute;
  right: calc(22.42% - 28px);
  width: 125px;
}
.app-store-1 {
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 1px);
}
.app-store2 {
  bottom: 43px;
  height: 43px;
  left: calc(22.86% - 29px);
  position: absolute;
  width: 126px;
}
.ch-cods {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 406px;
  overflow: hidden;
  position: relative;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.component-48-7 {
  height: 62px;
  left: 0px;
  position: absolute;
  top: calc(43.60% - 27px);
  width: 100%;
}
.component-49-1 {
  bottom: 0px;
  height: 80px;
  left: 2px;
  position: absolute;
  width: calc(100.00% - 2px);
}
.download-the-app2 {
  height: 2.83%;
  left: 16.32%;
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  top: 85.96%;
  white-space: nowrap;
  width: 34.73%;
}
.enjoy2 {
  height: 6.90%;
  left: 13.30%;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: 18.90%;
  white-space: nowrap;
  width: 32.27%;
}
.group-46 {
  bottom: calc(31.07% - 133px);
  height: 429px;
  left: calc(50.00% - 140px);
  position: absolute;
  width: 280px;
}
.line-22 {
  height: 2px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: calc(28.26% - 1px);
  width: calc(100.00% - 7px);
}
.logov2 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.textinput2 {
  background-color: transparent;
  border: 0;
  height: 25px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 0px;
  width: 279px;
}
.textinput2::-webkit-input-placeholder {
  color: rgba(112,112,112,0.6);
}
.textinput2:-ms-input-placeholder {
  color: rgba(112,112,112,0.6);
}
.textinput2::placeholder {
  color: rgba(112,112,112,0.6);
}
.x242 {
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 62px;
}
.x25-1 {
  bottom: 0px;
  display: block;
  height: 61px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  width: 61px;
opacity: 0;-webkit-transform: rotate(25deg);transform: rotate(25deg);
}
.x252 {
  bottom: 0px;
  height: 61px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  width: 61px;
}
.x72 {
  height: 165px;
  object-fit: cover;
  position: absolute;
  right: -53px;
  top: calc(6.80% - 11px);
  width: 165px;
}

.asset-12 {
  bottom: 0px;
  height: 157px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  width: 196px;
}
.component-70-1 {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 293px;
}
.x23 {
  height: 293px;
  left: calc(50.00% - 146px);
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 293px;
}

.asset-1 {
  background-position: 50% 50%;
  background-size: cover;
  bottom: 29px;
  height: 67px;
  position: absolute;
  right: calc(47.83% - 36px);
  width: 76px;
}
.component-43-1 {
  height: 160px;
  left: 0px;
  position: absolute;
  top: 17px;
  width: 135px;
}
.component-45-1 {
  background-color: var(--white);
  border-radius: 5px;
  top: 74%;
  height: 55px;
  left: 20.00%;
  position: absolute;
  width: 239px;
}
.component-52-1 {
  border-radius: 4px;
  height: 25px;
  position: absolute;
  right: 45px;
  top: 33px;
  width: 25px;
}
.component-71-1 {
  height: 220px;
  position: absolute;
  right: calc(47.47% - 145px);
  top: calc(16.72% - 37px);
  width: 306px;
}
.component-72-1 {
  height: 312px;
  left: calc(50.00% - 297px);
  position: absolute;
  top: 0px;
  width: 594px;
}
.component-73-1 {
  bottom: calc(35.70% - 154px);
  height: 431px;
  left: -118px;
  margin-bottom: 15px;
  position: absolute;
  width: calc(100.00% + 189px);
  top: 23%;
}
.croods5 {
  height: 265px;
  object-fit: cover;
  position: absolute;
  right: calc(103.53% - 351px);
  top: calc(130.80% - 346px);
  width: 339px;
}
.en-home {
  background-color: #F8EADB;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.get-invite-code {
  bottom: calc(5.56% - 2px);
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(46.94% - 89px);
  white-space: nowrap;
  width: 190px;
}
.logov3 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.text-1 {
  -webkit-text-stroke: 1px var(--licorice);
  height: calc(100.00% - 2px);
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  right: calc(37.50% - 6px);
  top: 6px;
  white-space: nowrap;
  width: 17px;
}
.text-2 {
  height: 70px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: relative;
  left: 10%;
  top: 84%;
  white-space: nowrap;
  width: 300px;
}
.title {
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 50px;
  position: absolute;
  top: 5px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
.x1 {
  height: 220px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: calc(50.00% - 110px);
  width: 220px;
}

.asset-13 {
  background-position: 50% 50%;
  background-size: cover;
  bottom: 124px;
  height: 67px;
  left: calc(43.23% - 33px);
  position: absolute;
  width: 76px;
}
.ch-home {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height:100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.component-43-12 {
  height: 120px;
  left: 0px;
  position: absolute;
  top: 36px;
  width: 150px;
}
.component-45-8 {
  background-color: var(--white);
  border-radius: 5px;
  top: 74%;
  height: 55px;
  left: 20%;
  position: absolute;
  width: 239px;
}
.component-52-2 {
  border-radius: 4px;
  height: 25px;
  position: absolute;
  right: 45px;
  top: 33px;
  width: 25px;
}
.component-71-3 {
  height: 220px;
  position: absolute;
  right: calc(47.00% - 143px);
  top: calc(16.72% - 37px);
  width: 305px;
}
.component-72-12 {
  height: 312px;
  left: calc(50.00% - 297px);
  position: absolute;
  top: 0px;
  width: 594px;
}
.component-73-3 {
  bottom: calc(38.90% - 160px);
  height: 411px;
  left: -118px;
  position: absolute;
  width: calc(100.00% + 189px);
}
.croods52 {
  height: 265px;
  object-fit: cover;
  position: absolute;
  right: calc(103.53% - 351px);
  top: calc(130.80% - 346px);
  width: 339px;
}
.en {
  -webkit-text-stroke: 1px var(--licorice);
  height: calc(100.00% - 5px);
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  right: calc(25.00% - 5px);
  top: 4px;
  white-space: nowrap;
  width: 21px;
}
.logov4 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.text-3 {
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 50px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
.text-4 {
  height: 50px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  left: 18%;
  top:  85%;
  white-space: nowrap;
  width: 270px;
}
.text-5 {
  height: 38px;
  left: calc(45.54% - 58px);
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  top: calc(102.94% - 39px);
  white-space: nowrap;
  width: 127px;
}
.x12 {
  height: 220px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: calc(50.00% - 110px);
  width: 220px;
}

.component-45-3 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: calc(3.70% - 2px);
  height: 55px;
  left: calc(50.00% - 120px);
  position: absolute;
  width: 239px;
}
.component-74-1 {
  height: 18.53%;
  position: absolute;
  right: calc(49.66% - 129px);
  top: 44.03%;
  width: 259px;
}
.en-typeform-home {
  background-color: #F8EADB;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  width: 100%;
}
.line-1 {
  bottom: calc(101.72% - 5px);
  height: 5px;
  left: calc(50.00% - 130px);
  object-fit: cover;
  position: absolute;
  width: 259px;
}
.logov7 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.prove-yourself {
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(25.40% - 45px);
  top: calc(105.56% - 39px);
  white-space: nowrap;
  width: 176px;
}
.text-7 {
  height: 140px;
  left: calc(0.54% - 1px);
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: calc(133.33% - 187px);
  white-space: nowrap;
  width: 167px;
}
.x3 {
  height: 235px;
  object-fit: cover;
  position: absolute;
  right: calc(48.24% - 113px);
  top: calc(18.37% - 43px);
  width: 235px;
}

.ch-typeform-home {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 405px;
  position: relative;
  width: 100%;
}
.component-62-1 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 30px;
  height: 55px;
  left: calc(50.00% - 120px);
  position: absolute;
  width: 239px;
}
.component-92-1 {
  bottom: calc(48.67% - 56px);
  height: 116px;
  left: 56px;
  position: absolute;
  width: 259px;
}
.line-12 {
  height: 5px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: -2px;
  width: 100%;
}
.logov8 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.ta {
  -webkit-text-stroke: 1px var(--licorice);
  color: var(--licorice);
  font-family: 'Poppins', Helvetica;
  font-size: 40px;
  height: calc(100.00% - 12px);
  left: 1px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: 20px;
  white-space: nowrap;
  width: calc(100.00% - 15px);
}
.text-8 {
  -webkit-text-stroke: 1px var(--licorice);
  bottom: 0px;
  height: 38px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  right: calc(32.18% - 49px);
  white-space: nowrap;
  width: 152px;
}
.x32 {
  height: 235px;
  left: calc(41.18% - 97px);
  object-fit: cover;
  position: absolute;
  top: 95px;
  width: 235px;
}

.component-45-4 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 28px;
  height: 55px;
  left: 83px;
  position: absolute;
  width: calc(100.00% - 166px);
}
.en-typeform-fail {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 405px;
  position: relative;
  width: 100%;
}
.leave {
  bottom: calc(5.56% - 2px);
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(49.39% - 37px);
  white-space: nowrap;
  width: 75px;
}
.line-13 {
  height: 5px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: calc(43.99% - 2px);
  width: 259px;
}
.logov9 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.oops {
  height: 56px;
  left: 56px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: calc(49.40% - 28px);
  white-space: nowrap;
  width: 146px;
}
.x105413x {
  height: 213px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: calc(21.04% - 45px);
  width: 168px;
}

.ch-typeform-fail {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 405px;
  position: relative;
  width: 100%;
}
.component-62-2 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 28px;
  height: 55px;
  left: calc(50.00% - 120px);
  position: absolute;
  width: 239px;
}
.line-14 {
  height: 5px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: calc(43.99% - 2px);
  width: 259px;
}
.logov10 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.nine {
  bottom: calc(33.71% - 8px);
  color: var(--licorice);
  font-family: 'Poppins', Helvetica;
  font-size: 15px;
  height: 23px;
  left: 56px;
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  white-space: nowrap;
  width: 139px;
}
.text-10 {
  bottom: calc(48.73% - 32px);
  height: 65px;
  left: 56px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  white-space: nowrap;
  width: 125px;
}
.text-9 {
  -webkit-text-stroke: 1px var(--licorice);
  bottom: calc(11.76% - 4px);
  height: 38px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  right: calc(49.20% - 26px);
  white-space: nowrap;
  width: 52px;
}
.x105413x2 {
  height: 213px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: calc(21.04% - 45px);
  width: 168px;
}

.component-22-2 {
  height: 234px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 259px;
}
.component-45-5 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 29px;
  height: 55px;
  position: absolute;
  right: calc(44.91% - 107px);
  width: 239px;
}
.component-65-1 {
  height: 54px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 177px;
}
.component-66-1 {
  bottom: 0px;
  height: 28px;
  position: absolute;
  right: 0px;
  width: 284px;
}
.component-67-1 {
  bottom: 0px;
  height: 95px;
  left: calc(50.00% - 143px);
  position: absolute;
  width: 286px;
}
.component-69-1 {
  height: 340px;
  position: absolute;
  right: calc(41.67% - 119px);
  top: calc(26.67% - 91px);
  width: 286px;
}
.en-typeform-pass {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 406px;
  position: relative;
  width: 100%;
}
.logov11 {
  height: 122px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.next {
  bottom: calc(11.11% - 4px);
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(49.18% - 28px);
  white-space: nowrap;
  width: 56px;
}
.perfect {
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}
.text-11 {
  color: var(--tundora);
  font-family: 'Poppins', Helvetica;
  font-size: 20px;
  height: calc(100.00% + 2px);
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
  width: calc(100.00% + 2px);
}

.ch-typeform-pass {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 406px;
  position: relative;
  width: 100%;
}
.component-64-1 {
  bottom: calc(3.70% - 2px);
  height: 55px;
  left: calc(49.70% - 119px);
  position: absolute;
  width: 239px;
}
.component-89-1 {
  height: 379px;
  left: calc(46.48% - 123px);
  position: absolute;
  top: 126px;
  width: 264px;
}
.group-43 {
  height: 234px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 5px);
}
.logov12 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.perfect2 {
  bottom: calc(21.83% - 12px);
  height: 56px;
  left: 6px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  white-space: nowrap;
  width: 179px;
}
.rectangle-28 {
  border-radius: 28px;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.text-12 {
  -webkit-text-stroke: 1px var(--licorice);
  bottom: calc(11.76% - 4px);
  height: 38px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  right: calc(49.20% - 26px);
  white-space: nowrap;
  width: 52px;
}
.text-13 {
  bottom: -2px;
  height: 34px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  width: calc(100.00% - 67px);
}

.android {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  height: 50px;
  margin-left: 12px;
  object-fit: cover;
  width: 143px;
}
.appi {
  left: 60px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}
.app-store {
  height: 50px;
  object-fit: cover;
  width: 143px;
}
.asset-8 {
  height: 21px;
  object-fit: cover;
  width: 16px;
  cursor: pointer;
}

.code11 {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 26px;
  min-height: 30px;
  position: absolute;
  top: 135px;
  width: 246px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.code21.code11 {
  top: 180px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.code31.code11 {
  top: 225px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.line-3 {
  height: 1px;
  margin-top: 4px;
  object-fit: cover;
  width: 246px;
}
.xyz2423-1 {
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-right: 90.0px;
  min-height: 22px;
  min-width: 61px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}

.rgba25475511 {
  height: 64px;
  object-fit: cover;
  width: 38px;
  margin-left: 230px;
  margin-top: 53px;
}

.codes {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: #F8EADB;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  padding: 14px 20px;
  width: 100%;
}
.component-24-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 22px;
  left: 73px;
  min-width: 175px;
  position: absolute;
  top: 269px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.component-25-1 {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 423px;
  width: 272px;
}
.component-26-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 51px;
  margin-top: 19px;
  min-width: 298px;
}
.component-27-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 26px;
  width: 298px;
  
}
.component-28-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: var(--white);
  border-radius: 27px;
  display: -webkit-flex;
  display: flex;
  height: 460px;
  min-width: 345px;
  padding: 18px 23px;
}
.component-29-1 {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  background-color: var(--white);
  border-radius: 26px;
  display: -webkit-flex;
  display: flex;
  height: 169px;
  margin-top: 16px;
  min-width: 345px;
  padding: 18px 23px;
}
.component-31-1 {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-align-self: center;
          align-self: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 28px;
  min-height: 645px;
  width: 345px;
}
.copycomp {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  margin-left: 44px;
  min-width: 72px;
}
.envelopentxt {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  height: 40px;
  left: 14px;
  min-width: 146px;
  position: absolute;
  top: 322px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.icon-feather-send {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 21px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 0.0px;
  min-width: 21px;
  -webkit-transform: rotate(45.00deg);
          transform: rotate(45.00deg);
}
.mask-groupi {
  height: 39px;
  object-fit: cover;
  width: 21px;
}
.mask-groupii {
  height: 34px;
  object-fit: cover;
  width: 34px;
}
.mask-groupiii {
  height: 64px;
  object-fit: cover;
  width: 115px;
}
.mask-group-56i {
  height: 34px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 34px;
}
.nineapp {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 20px;
  margin-bottom: -8px;
  margin-left: 26px;
  min-height: 34px;
  min-width: 178px;
  white-space: nowrap;
}
.overlap-groupi {
  height: 388px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 267px;
}
.overlap-group1i {
  height: 44px;
  margin-top: -11.54px;
  position: relative;
  width: 44px;
}
.overlap-group2i {
  height: 388px;
  margin-right: 0.45px;
  margin-top: -1px;
  position: relative;
  width: 272px;
}
.path-10 {
  height: 18px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 7.5px;
  -webkit-transform: rotate(-47.00deg);
          transform: rotate(-47.00deg);
  width: 18px;
}
.path-11 {
  height: 31px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  -webkit-transform: rotate(-45.00deg);
          transform: rotate(-45.00deg);
  width: 31px;
  outline: transparent;
}
.path-6 {
  height: 2px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 385px;
  width: 247px;
}
.phonentxt {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  height: 39px;
  left: 29px;
  min-width: 156px;
  position: absolute;
  top: 62px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.sendcomp {
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 30px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 23px;
  margin-top: 6px;
  min-width: 58px;
  padding: 0.7px 0;
}
.span {
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-size: 20px;
  font-weight: 700;
}
.span-1 {
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-size: 20px;
  font-weight: 300;
  
}
.span-2 {
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-size: 10px;
  font-weight: 300;
}

.texti {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 10px;
  margin-right: 44px;
  margin-top: 10px;
  min-height: 18px;
  min-width: 44px;
  white-space: nowrap;
}
.textii {
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-bottom: 0.41px;
  margin-left: 6px;
  min-height: 22px;
  min-width: 120px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
}
.texta {
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-left: 10px;
  min-height: 22px;
  min-width: 125px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
}
.texto {
  letter-spacing: .00px;
  line-height: 12px;
  margin-bottom: -2.5px;
  margin-left: 6px;
  min-height: 19px;
  min-width: 50px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  cursor: pointer;
}
.text-5i {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 12px;
  margin-left: 0px;
  min-height: 19px;
  min-width: 26px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.title2i {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  height: 35px;
  min-width: 238px;
  margin-left: -2px;
}
.xl {
  background-color: transparent;
  border: 1px;
  border-color: transparent;
  height: 28px;
  left: 25px;
  letter-spacing: 0.00px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 360px;
  width: 248px;
  outline:none
}
.x25i {
  height: 40px;
  object-fit: cover;
  width: 40px;
}
.xl::-webkit-input-placeholder {
  color: rgba(21,21,21,0.6);
}
.xl:-ms-input-placeholder {
  color: rgba(21,21,21,0.6);
}
.xl::placeholder {
  color: rgba(21,21,21,0.6);
}
.xyz2423i {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-bottom: -5px;
  min-height: 22px;
  min-width: 61px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: "LucidaGrande-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6043aff9a0bf44d7dde2f986/fonts/lucida-grande.zip")
    format("None");
}

@font-face {
  font-family: "ArgentCF-Super";
  font-style: normal;
  font-weight: 400;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6056085f8c55bf8192363264/fonts/fontsfree-net-argent-cf-bold-1.ttf")
    format("truetype");
}

@font-face {
  font-family: "Baskerville-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6056085f8c55bf8192363264/fonts/fontsfree-net-arvo-regular.ttf")
    format("truetype");
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
.animate-enter {
  -webkit-animation: animate-enter-frames 0.2s ease 0s 1 normal forwards;
          animation: animate-enter-frames 0.2s ease 0s 1 normal forwards;
  display: block;
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.animate-enter1 {
  -webkit-animation: animate-enter1-frames 0.2s ease 0s 1 normal forwards;
          animation: animate-enter1-frames 0.2s ease 0s 1 normal forwards;
  display: block;
  opacity: 0;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid rgba(112, 112, 112, 1);
  border: 1px solid var(--dove-gray);
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: rgba(112, 112, 112, 1);
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}



.poppins-bold-licorice-12px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-14px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-16px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.poppins-light-licorice-10px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
}
.poppins-light-licorice-20px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
}
.poppins-medium-licorice-12px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}
.poppins-medium-licorice-14px {
  color: rgba(21, 21, 21, 1);
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}







:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
@-webkit-keyframes animate-enter-frames {
  from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes animate-enter-frames {
  from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes animate-enter1-frames {
  from {
    opacity: 0;
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes animate-enter1-frames {
  from {
    opacity: 0;
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

