.android {
  align-self: flex-end;
  height: 50px;
  margin-left: 12px;
  object-fit: cover;
  width: 143px;
}
.appi {
  left: 60px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}
.app-store {
  height: 50px;
  object-fit: cover;
  width: 143px;
}
.asset-8 {
  height: 21px;
  object-fit: cover;
  width: 16px;
  cursor: pointer;
}

.code11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 26px;
  min-height: 30px;
  position: absolute;
  top: 135px;
  width: 246px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.code21.code11 {
  top: 180px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.code31.code11 {
  top: 225px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.line-3 {
  height: 1px;
  margin-top: 4px;
  object-fit: cover;
  width: 246px;
}
.xyz2423-1 {
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-right: 90.0px;
  min-height: 22px;
  min-width: 61px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}

.rgba25475511 {
  height: 64px;
  object-fit: cover;
  width: 38px;
  margin-left: 230px;
  margin-top: 53px;
}

.codes {
  align-items: flex-start;
  background-color: #F8EADB;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 14px 20px;
  width: 100%;
}
.component-24-1 {
  align-items: flex-start;
  display: flex;
  height: 22px;
  left: 73px;
  min-width: 175px;
  position: absolute;
  top: 269px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.component-25-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 423px;
  width: 272px;
}
.component-26-1 {
  align-items: flex-start;
  display: flex;
  height: 51px;
  margin-top: 19px;
  min-width: 298px;
}
.component-27-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 26px;
  width: 298px;
  
}
.component-28-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 27px;
  display: flex;
  height: 460px;
  min-width: 345px;
  padding: 18px 23px;
}
.component-29-1 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 26px;
  display: flex;
  height: 169px;
  margin-top: 16px;
  min-width: 345px;
  padding: 18px 23px;
}
.component-31-1 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  min-height: 645px;
  width: 345px;
}
.copycomp {
  align-items: flex-end;
  display: flex;
  margin-left: 44px;
  min-width: 72px;
}
.envelopentxt {
  align-items: center;
  display: flex;
  height: 40px;
  left: 14px;
  min-width: 146px;
  position: absolute;
  top: 322px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.icon-feather-send {
  align-items: flex-start;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  margin-top: 0.0px;
  min-width: 21px;
  transform: rotate(45.00deg);
}
.mask-groupi {
  height: 39px;
  object-fit: cover;
  width: 21px;
}
.mask-groupii {
  height: 34px;
  object-fit: cover;
  width: 34px;
}
.mask-groupiii {
  height: 64px;
  object-fit: cover;
  width: 115px;
}
.mask-group-56i {
  height: 34px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 34px;
}
.nineapp {
  align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 20px;
  margin-bottom: -8px;
  margin-left: 26px;
  min-height: 34px;
  min-width: 178px;
  white-space: nowrap;
}
.overlap-groupi {
  height: 388px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 267px;
}
.overlap-group1i {
  height: 44px;
  margin-top: -11.54px;
  position: relative;
  width: 44px;
}
.overlap-group2i {
  height: 388px;
  margin-right: 0.45px;
  margin-top: -1px;
  position: relative;
  width: 272px;
}
.path-10 {
  height: 18px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 7.5px;
  transform: rotate(-47.00deg);
  width: 18px;
}
.path-11 {
  height: 31px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  transform: rotate(-45.00deg);
  width: 31px;
  outline: transparent;
}
.path-6 {
  height: 2px;
  left: 25px;
  object-fit: cover;
  position: absolute;
  top: 385px;
  width: 247px;
}
.phonentxt {
  align-items: flex-end;
  display: flex;
  height: 39px;
  left: 29px;
  min-width: 156px;
  position: absolute;
  top: 62px;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.sendcomp {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: flex-end;
  margin-right: 23px;
  margin-top: 6px;
  min-width: 58px;
  padding: 0.7px 0;
}
.span {
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-size: 20px;
  font-weight: 700;
}
.span-1 {
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-size: 20px;
  font-weight: 300;
  
}
.span-2 {
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-size: 10px;
  font-weight: 300;
}

.texti {
  align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 10px;
  margin-right: 44px;
  margin-top: 10px;
  min-height: 18px;
  min-width: 44px;
  white-space: nowrap;
}
.textii {
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-bottom: 0.41px;
  margin-left: 6px;
  min-height: 22px;
  min-width: 120px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
}
.texta {
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-left: 10px;
  min-height: 22px;
  min-width: 125px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 500;
}
.texto {
  letter-spacing: .00px;
  line-height: 12px;
  margin-bottom: -2.5px;
  margin-left: 6px;
  min-height: 19px;
  min-width: 50px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
  cursor: pointer;
}
.text-5i {
  align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 12px;
  margin-left: 0px;
  min-height: 19px;
  min-width: 26px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
.title2i {
  align-items: flex-start;
  display: flex;
  height: 35px;
  min-width: 238px;
  margin-left: -2px;
}
.xl {
  background-color: transparent;
  border: 1px;
  border-color: transparent;
  height: 28px;
  left: 25px;
  letter-spacing: 0.00px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 360px;
  width: 248px;
  outline:none
}
.x25i {
  height: 40px;
  object-fit: cover;
  width: 40px;
}
.xl::placeholder {
  color: rgba(21,21,21,0.6);
}
.xyz2423i {
  align-self: flex-end;
  letter-spacing: 0.00px;
  line-height: 14px;
  margin-bottom: -5px;
  min-height: 22px;
  min-width: 61px;
  white-space: nowrap;
  color: rgba(21,21,21,1.0);
  font-family: 'Poppins', Helvetica;
  font-weight: 400;
}
