.ch-typeform-pass {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 406px;
  position: relative;
  width: 100%;
}
.component-64-1 {
  bottom: calc(3.70% - 2px);
  height: 55px;
  left: calc(49.70% - 119px);
  position: absolute;
  width: 239px;
}
.component-89-1 {
  height: 379px;
  left: calc(46.48% - 123px);
  position: absolute;
  top: 126px;
  width: 264px;
}
.group-43 {
  height: 234px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 5px);
}
.logov12 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.perfect2 {
  bottom: calc(21.83% - 12px);
  height: 56px;
  left: 6px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  white-space: nowrap;
  width: 179px;
}
.rectangle-28 {
  border-radius: 28px;
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: 100%;
}
.text-12 {
  -webkit-text-stroke: 1px var(--licorice);
  bottom: calc(11.76% - 4px);
  height: 38px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  right: calc(49.20% - 26px);
  white-space: nowrap;
  width: 52px;
}
.text-13 {
  bottom: -2px;
  height: 34px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  width: calc(100.00% - 67px);
}
