@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,300,700");

@font-face {
  font-family: "LucidaGrande-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6043aff9a0bf44d7dde2f986/fonts/lucida-grande.zip")
    format("None");
}

@font-face {
  font-family: "ArgentCF-Super";
  font-style: normal;
  font-weight: 400;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6056085f8c55bf8192363264/fonts/fontsfree-net-argent-cf-bold-1.ttf")
    format("truetype");
}

@font-face {
  font-family: "Baskerville-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6056085f8c55bf8192363264/fonts/fontsfree-net-arvo-regular.ttf")
    format("truetype");
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
.animate-enter {
  animation: animate-enter-frames 0.2s ease 0s 1 normal forwards;
  display: block;
  opacity: 0;
  transform: scale(1.1);
}
.animate-enter1 {
  animation: animate-enter1-frames 0.2s ease 0s 1 normal forwards;
  display: block;
  opacity: 0;
  transform: rotate(25deg);
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-15px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-25px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-40px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-45px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.argentcf-super-normal-licorice-50px {
  color: var(--licorice);
  font-family: "ArgentCF-Super", Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-dove-gray-16px {
  color: var(--dove-gray);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-15px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-light-licorice-21px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-20px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.poppins-normal-licorice-25px {
  color: var(--licorice);
  font-family: "Poppins", Helvetica;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}



.poppins-bold-licorice-12px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-14px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-16px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-16px);
  font-style: normal;
  font-weight: 700;
}
.poppins-bold-licorice-20px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}
.poppins-light-licorice-10px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
}
.poppins-light-licorice-20px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
}
.poppins-medium-licorice-12px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}
.poppins-medium-licorice-14px {
  color: var(--licorice);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}







:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
:root {
  --albescent-white: rgba(248, 234, 219, 1);
  --dove-gray: rgba(112, 112, 112, 1);
  --licorice: rgba(21, 21, 21, 1);
  --tundora: rgba(70, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
}
@keyframes animate-enter-frames {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animate-enter1-frames {
  from {
    opacity: 0;
    transform: rotate(25deg);
  }
  to {
    opacity: 1;
    transform: rotate(0);
  }
}
