.android-1 {
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 1px);
}
.android2 {
  bottom: 43px;
  height: 43px;
  position: absolute;
  right: calc(22.42% - 28px);
  width: 125px;
}
.app-store-1 {
  height: 100%;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 1px);
}
.app-store2 {
  bottom: 43px;
  height: 43px;
  left: calc(22.86% - 29px);
  position: absolute;
  width: 126px;
}
.ch-cods {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 406px;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.component-48-7 {
  height: 62px;
  left: 0px;
  position: absolute;
  top: calc(43.60% - 27px);
  width: 100%;
}
.component-49-1 {
  bottom: 0px;
  height: 80px;
  left: 2px;
  position: absolute;
  width: calc(100.00% - 2px);
}
.download-the-app2 {
  height: 2.83%;
  left: 16.32%;
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  top: 85.96%;
  white-space: nowrap;
  width: 34.73%;
}
.enjoy2 {
  height: 6.90%;
  left: 13.30%;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: 18.90%;
  white-space: nowrap;
  width: 32.27%;
}
.group-46 {
  bottom: calc(31.07% - 133px);
  height: 429px;
  left: calc(50.00% - 140px);
  position: absolute;
  width: 280px;
}
.line-22 {
  height: 2px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: calc(28.26% - 1px);
  width: calc(100.00% - 7px);
}
.logov2 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.textinput2 {
  background-color: transparent;
  border: 0;
  height: 25px;
  left: 0px;
  letter-spacing: 0.00px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  resize: none;
  text-align: left;
  top: 0px;
  width: 279px;
}
.textinput2::placeholder {
  color: rgba(112,112,112,0.6);
}
.x242 {
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 62px;
}
.x25-1 {
  bottom: 0px;
  display: block;
  height: 61px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  width: 61px;
opacity: 0;transform: rotate(25deg);
}
.x252 {
  bottom: 0px;
  height: 61px;
  object-fit: cover;
  position: absolute;
  right: 0px;
  width: 61px;
}
.x72 {
  height: 165px;
  object-fit: cover;
  position: absolute;
  right: -53px;
  top: calc(6.80% - 11px);
  width: 165px;
}
