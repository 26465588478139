.component-45-4 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 28px;
  height: 55px;
  left: 83px;
  position: absolute;
  width: calc(100.00% - 166px);
}
.en-typeform-fail {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 405px;
  position: relative;
  width: 100%;
}
.leave {
  bottom: calc(5.56% - 2px);
  height: 37px;
  letter-spacing: 0.00px;
  line-height: 21px;
  position: absolute;
  right: calc(49.39% - 37px);
  white-space: nowrap;
  width: 75px;
}
.line-13 {
  height: 5px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: calc(43.99% - 2px);
  width: 259px;
}
.logov9 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.oops {
  height: 56px;
  left: 56px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  top: calc(49.40% - 28px);
  white-space: nowrap;
  width: 146px;
}
.x105413x {
  height: 213px;
  left: 57px;
  object-fit: cover;
  position: absolute;
  top: calc(21.04% - 45px);
  width: 168px;
}
