.ch-typeform-fail {
  background-color: var(--albescent-white);
  height: 100vh;
  min-height: 812px;
  min-width: 405px;
  position: relative;
  width: 100%;
}
.component-62-2 {
  background-color: var(--white);
  border-radius: 28px;
  bottom: 28px;
  height: 55px;
  left: calc(50.00% - 120px);
  position: absolute;
  width: 239px;
}
.line-14 {
  height: 5px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: calc(43.99% - 2px);
  width: 259px;
}
.logov10 {
  height: 122px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: -12px;
  width: 122px;
}
.nine {
  bottom: calc(33.71% - 8px);
  color: var(--licorice);
  font-family: 'Poppins', Helvetica;
  font-size: 15px;
  height: 23px;
  left: 56px;
  letter-spacing: 0.00px;
  line-height: 15px;
  position: absolute;
  white-space: nowrap;
  width: 139px;
}
.text-10 {
  bottom: calc(48.73% - 32px);
  height: 65px;
  left: 56px;
  letter-spacing: 0.00px;
  line-height: 45px;
  position: absolute;
  white-space: nowrap;
  width: 125px;
}
.text-9 {
  -webkit-text-stroke: 1px var(--licorice);
  bottom: calc(11.76% - 4px);
  height: 38px;
  letter-spacing: 0.00px;
  line-height: 20px;
  position: absolute;
  right: calc(49.20% - 26px);
  white-space: nowrap;
  width: 52px;
}
.x105413x2 {
  height: 213px;
  left: 56px;
  object-fit: cover;
  position: absolute;
  top: calc(21.04% - 45px);
  width: 168px;
}
