.component-48-5 {
  height: 62px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: calc(100.00% - 1px);
}
.component-48-6 {
  top: calc(21.80% - 14px);
}
.component-48-8 {
  bottom: calc(34.60% - 21px);
  top: unset;
}
.x243 {
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 62px;
}
